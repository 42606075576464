.die-container-text-title {
  font-weight: bold;
  font-size: 1.5rem;
}

.die-container {
  margin-top: 10px;
  border-style: solid;
  border-width: 0.1px;
  border-radius: 6px;
  border-color: rgba(0, 0, 0, 0.176);
  padding: 1.2em;
}
