.die-container {
  margin-top: 10px;
  border-style: solid;
  border-width: 0.1px;
  border-radius: 6px;
  border-color: rgba(0, 0, 0, 0.176);
  padding: 1em;
}

.die-container-text-title {
  font-weight: bold;
  font-size: 1.5rem;
  color: black;
}

.die-container-symbol-title {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 2.5rem;
  color: black;
}

.die-container-empty {
  color: black;
}
