.die-button {
  margin-left: 0.3em;
  margin-right: 0.3em;
  margin-bottom: 0.3em;
  padding: 0;
  cursor: pointer;
  display: inline-block;
  text-align: center;
}

.die-image {
  font-size: 3em;
  padding: 0em;
}

.die-image-popover {
  font-size: 3em;
  padding: 0em;
}

.die-non-d6-div {
  border-radius: 5px;
  height: 2.8em;
  width: 2.8em;
  margin: 0.005em;
  text-align: center;
  vertical-align: middle;
}

.die-non-d6-span {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 2em;
  color: black;
}

.die-volatility {
  color: #f7952e;
}

.die-salt {
  color: #858c8e;
}

.die-fire {
  color: #d6351c;
}

.die-water {
  color: #2463d1;
}

.die-earth {
  color: #1f8022;
}

.die-brimstone {
  color: #ffc400f2;
}

.die-mineral {
  color: #000000;
}

.die-air {
  color: #5de2db;
}

.die-arsenic {
  background-color: #6adb64;
}

.die-iron {
  background-color: #d69550;
}

.die-mandrake {
  background-color: #ff3399d0;
}

.die-quicksilver {
  background-color: #bfcfd3;
}

.die-secret {
  background-color: #bc9bff;
}

/* Applying Animation */
.die-shaking {
  animation-name: wobble;
  animation-duration: 1s;
}

/* Setting Animation effect to the 
     dice using css keyframe */
@keyframes wobble {
  from {
    transform: translate3d(0, 0, 0);
  }
  15% {
    transform: translate3d(-25%, 0, 0) rotate3d(0, 0, 1, -5deg);
  }
  30% {
    transform: translate3d(20%, 0, 0) rotate3d(0, 0, 1, 3deg);
  }
  45% {
    transform: translate3d(-15%, 0, 0) rotate3d(0, 0, 1, -3deg);
  }
  60% {
    transform: translate3d(10%, 0, 0) rotate3d(0, 0, 1, 2deg);
  }
  75% {
    transform: translate3d(-5%, 0, 0) rotate3d(0, 0, 1, -1deg);
  }
  to {
    transform: translate3d(0, 0, 0);
  }
}

.img-wrapper {
  position: relative;
  z-index: 10;
  left: -20px;
  top: -57px;
  display: block;
  height: 0px;
}
